<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signup-on': this.state === 'signup'
      }"
      id="kt_login"
    >
      <!--begin::Aside-->

      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signup-->
          <div class="login-form login-signup">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signup_form"
              @submit.stop.prevent="onSubmitRegister()"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Inscription {{ playzoneInfo.preposition }}
                  {{ playzoneInfo.playzone_name }}
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Entrez les informations pour créer votre compte
                </p>
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="text"
                  placeholder="Prénom"
                  name="fullname"
                  ref="fullname"
                  autocomplete="off"
                  v-model="form.fullname"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="text"
                  placeholder="Nom"
                  name="lastname"
                  ref="lastname"
                  autocomplete="off"
                  v-model="form.lastname"
                />
              </div>

              <div class="form-group">
                <div class="d-flex">
                  <b-form-select
                    v-model="form.countryCode"
                    :options="indicatifs"
                    name="countrycode"
                    class="form-control flex-sm-grow-1 form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6 col-xs-3"
                    style="flex-basis: 100px; margin-right: 1rem"
                  ></b-form-select>
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                    type="text"
                    placeholder="Téléphone"
                    name="phone"
                    ref="rphone"
                    autocomplete="on"
                    v-model="form.phone"
                  />
                </div>
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  placeholder="Email"
                  name="email"
                  ref="remail"
                  autocomplete="on"
                  v-model="form.email"
                />
              </div>
              <div class="form-group">
                <label class="checkbox mb-0">
                  <input
                    type="checkbox"
                    name="agree"
                    style="border:1px solid #fff"
                  />
                  <span class="mr-2"></span>
                  J'accepte
                  <a href="#" class="ml-2"> les termes et conditions</a>.
                </label>
              </div>
              <div
                class="form-group d-flex flex-wrap pb-lg-0 pb-3 align-items-center text-center"
              >
                <button
                  ref="kt_login_signup_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                  style="width:150px;"
                  :disabled="disableButton"
                >
                  Je m'inscris
                </button>
                <button
                  type="button"
                  id="kt_login_signup_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signup')"
                >
                  Annuler
                </button>
              </div>
            </form>
          </div>
          <!--end::Signup-->
        </div>
        <!--begin::Content footer
        <div
            class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0">
          <a href="#" class="text-primary font-weight-bolder font-size-h5">Termes</a
          >
          <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5">Tutos</a>
          <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5"
          >Nous Contacter</a
          >
        </div>
        -->

        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import ApiService from "@/core/services/api.service";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
//import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { REGISTER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

export default {
  name: "playzone-subscribe",

  data() {
    return {
      state: "signup",
      pause: false,
      disableButton: false,
      playzoneInfo: {
        playzone_name: "Playzone Canal",
        id: 0,
        preposition: ""
      },
      subscription: {},
      // Remove this dummy login info
      form: {
        playerId: "",
        platform: "",
        gameId: "",
        platformId: "",
        fullname: "",
        lastname: "",
        email: "",
        phone: "",
        countryCode: null
      },
      indicatifs: [],
      mainLogo: process.env.BASE_URL + "media/logos/logo-letter-1.png"
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    }
  },
  created() {
    const payload = {
      platform: this.$route.params.platform,
      platformId: this.$route.params.platformId,
      gameId: this.$route.params.gameId
    };
    //console.log(payload)
    ApiService.post("member-init", payload)
      .then(({ data: { player, gameInfo } }) => {
        //console.log(player);
        this.form.fullname =
          player.firstname != null || player.firstname !== "NULL"
            ? player.firstname
            : "";
        this.form.lastname =
          player.lastname != null || player.lastname !== "NULL"
            ? player.lastname
            : "";
        this.form.phone =
          player.phone != null || player.phone !== "NULL" ? player.phone : "";
        this.form.email =
          player.email != null || player.email !== "NULL" ? player.email : "";
        this.form.playerId =
          player.id != null || player.id !== "NULL" ? player.id : "";
        this.playzoneInfo = gameInfo.playzone;
        this.form.countryCode = player.phone
          ? player.phone.toString().substring(0, 3)
          : null;
        this.subscription = player.subscription;

        if (
          this.form.fullname !== "" &&
          this.form.lastname !== "" &&
          this.form.phone !== "" &&
          this.form.email !== "" &&
          this.form.fullname !== null &&
          this.form.lastname !== null &&
          this.form.phone !== null &&
          this.form.email !== null
        ) {
          sessionStorage.removeItem("memberInfo");
          sessionStorage.removeItem("redirectUri");
          const payload = {
            platform: this.form.platform,
            platformId: this.form.platformId,
            userId: this.form.playerId,
            email: player.email,
            firstname: player.firstname,
            lastname: player.lastname,
            phone: player.phone,
            countryCode: this.form.countryCode,
            subscription: player.subscription
          };
          sessionStorage.setItem(
            "playzoneInfo",
            JSON.stringify(this.playzoneInfo)
          );
          sessionStorage.setItem("memberInfo", JSON.stringify(payload));
          sessionStorage.setItem("redirectUri", gameInfo.redirectLink);
          //console.log(gameInfo.redirectLink)
          this.disableButton = false;
          this.pause = false;
          this.$router.push({ name: "validate-subscription" }).catch(err => {
            console.log(err);
          });
        }
        this.indicatifs = [{ value: null, text: "Code Pays" }];
        try {
          const codePays = gameInfo.playzone.country_code.split(",");
          codePays.forEach(code => {
            code = code.trim();
            const option = {
              value: code,
              text: "+" + code
            };
            this.indicatifs.push(option);
          });
        } catch (e) {
          const codePays = gameInfo.playzone.country_code;
          const option = {
            value: codePays,
            text: "+" + codePays
          };
          this.indicatifs.push(option);
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  mounted() {
    //const signin_form = KTUtil.getById("kt_login_signin_form");
    //console.log(this.mainLogo);
    const signup_form = KTUtil.getById("kt_login_signup_form");
    this.fv1 = formValidation(signup_form, {
      locale: "fr_FR",
      fields: {
        fullname: {
          validators: {
            notEmpty: {
              message: "Vous devez renseigner votre prénom"
            }
          }
        },
        lastname: {
          validators: {
            notEmpty: {
              message: "Vous devez renseigner votre nom de famille"
            }
          }
        },
        countrycode: {
          validators: {
            notEmpty: {
              message: "Choisissez le code du Pays"
            }
          }
        },
        email: {
          validators: {
            notEmpty: {
              message: "Champ obligatoire"
            },
            emailAddress: {
              message: "Veuillez entrer une adresse email valide"
            }
          }
        },
        phone: {
          validators: {
            notEmpty: {
              message: "Vous devez renseigner un numéro de téléphone"
            },
            validatePhoneNumber: {
              message: "Vous devez entrer un numéro de téléphone valide"
            }
          }
        },
        agree: {
          validators: {
            notEmpty: {
              message:
                "Vous devez accepter les termes et conditions de la plateforme"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        /*submitButton: new SubmitButton(),*/
        bootstrap: new Bootstrap()
      }
    });
    this.form.platform = this.$route.params.platform;
    this.form.gameId = this.$route.params.gameId;
    this.form.platformId = this.$route.params.platformId;
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },

    onSubmitRegister: function() {
      //console.log('func fires');

      if (this.isValidEmail(this.form.email) !== true) {
        Swal.fire({
          title: "Email non valide",
          text: "Veuillez entrer une adresse email valie",
          icon: "error",
          heightAuto: false
        });
      }

      this.disableButton = true;
      this.fv1.validate();

      this.fv1.on("core.form.valid", () => {
        const email = this.form.email;
        const firstname = this.form.fullname;
        const lastname = this.form.lastname;
        const phone = this.form.phone;

        // clear existing errors
        //this.$store.dispatch(LOGOUT);
        // set spinner to submit button
        const submitButton = this.$refs["kt_login_signup_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        //setTimeout(() => {
        // send register request
        //console.log("send register")
        const payload = {
          platform: this.form.platform,
          platformId: this.form.platformId,
          userId: this.form.playerId,
          email,
          firstname,
          lastname,
          phone,
          countryCode: this.form.countryCode
        };
        //console.log(payload)
        if (this.pause === false) {
          this.pause = true;
          this.$store
            .dispatch(REGISTER, payload)
            .then(data => {
              if (data === false || (data.error && data.error === true)) {
                let errorMessage;
                if (data === false)
                  errorMessage =
                    "Une erreur est survenue, veuillez contacter l'administrateur du jeu";

                Swal.fire({
                  title: "Erreur",
                  text: data.message || errorMessage,
                  icon: "error",
                  heightAuto: false
                });
                this.pause = false;
              } else {
                payload.hasSMS = true;

                //console.log(JSON.parse(data.activation).sms);
                if (data.activation) {
                  if (JSON.parse(data.activation).sms === 0) {
                    payload.hasSMS = false;
                  }
                }
                sessionStorage.removeItem("memberInfo");
                sessionStorage.setItem(
                  "playzoneInfo",
                  JSON.stringify(this.playzoneInfo)
                );
                payload.subscription = this.subscription;
                sessionStorage.setItem("memberInfo", JSON.stringify(payload));
                this.disableButton = false;
                this.pause = false;
                this.$router
                  .push({ name: "validate-subscription" })
                  .catch(err => {
                    console.log(err);
                  });
                this.pause = false;
              }
            })
            .catch(err => {
              console.log(err);
              this.pause = false;
              this.disableButton = false;
            });
        }
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        //}, 1000);
      });
      this.disableButton = false;
      this.fv1.on("core.form.invalid", () => {
        Swal.fire({
          title: "Le formulaire comporte des erreurs",
          text: "Veuillez remplir les champs avec des données valides !",
          icon: "error",
          heightAuto: false
        });
      });
    },
    isValidEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  }
};
</script>
<style scoped>
@media screen and (min-width: 769px) {
  .swal-wide {
    width: 760px !important;
    margin: auto;
  }
}
</style>
